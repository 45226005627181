import { graphql } from "gatsby";
import { Helmet, Link, Trans } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../../helpers/constants";
import { ArrowRightShortIcon, CollapseMenuIcon, ExpandMenuIcon, } from "../../../images/nigeria/Icons";


import HeroImage from "../../../images/nigeria/personal/index/yellow-background.png";
import HeroImageMobile from "../../../images/nigeria/personal/index/index-hero-mobile.png";

// import heroCardMobile from "../../../images/nigeria/personal/hero_card_mobile.svg";
import heroCardMobile from "../../../images/nigeria/personal/card-phone-2.png";

import secureBankVector from "../../../images/nigeria/personal/secureBankVector.svg";
import secure from "../../../images/nigeria/personal/secure.png";
import protect from "../../../images/nigeria/personal/protect.png";
import support from "../../../images/nigeria/personal/support.png";
import papieesMeatro from "../../../images/nigeria/personal/papieesMeatro.png";
import thePlace from "../../../images/nigeria/personal/thePlace.png";
import kilimanjar from "../../../images/nigeria/personal/kilimanjar.png";
import princeEbeanaSuperMarket from "../../../images/nigeria/personal/princeEbeanaSuperMarket.png";
import justRiteSuperstore from "../../../images/nigeria/personal/justRiteSuperstore.png";
import eSportaSalts from "../../../images/nigeria/personal/eSportaSalts.png";
import amalaSky from "../../../images/nigeria/personal/amalaSky.png";
import mobil from "../../../images/nigeria/personal/mobil.png";
import gigLogistics from "../../../images/nigeria/personal/gigLogistics.png";
import cubanaGroup from "../../../images/nigeria/personal/cubanaGroup.png";
import Mobile from "../../../images/nigeria/personal/mobile.svg";
import Checklist from "../../../images/nigeria/personal/checklist.svg";
import CardOnHand from "../../../images/nigeria/personal/card-hand.svg";
import SeamlessIcon from "../../../images/nigeria/personal/index/seamless.svg";
import LocationIcon from "../../../images/nigeria/personal/index/location.svg";
import WalletIcon from "../../../images/nigeria/personal/index/wallet.svg";

import AppStoreImage from "../../../images/nigeria/footer/app-store.svg";
import PlayStoreImage from "../../../images/nigeria/footer/google-play.svg";

import AppStoreImageMobile from "../../../images/nigeria/personal/index/app-store-mobile.svg";
import PlayStoreImageMobile from "../../../images/nigeria/personal/index/play-store-mobile.svg";

import { LayoutCard, MerchantContainerDiv } from "./banking";
import BBNGameBanner from "../../../components/nigeria/BBNGameBanner";



const PersonalPage = ({ data }) => {
  const businessTips = data.businessTips.nodes;
  const businesses = [papieesMeatro, thePlace, kilimanjar, princeEbeanaSuperMarket, justRiteSuperstore,
    eSportaSalts, amalaSky, mobil, gigLogistics, cubanaGroup];
  return (
    <Layout
      pageTitle={Nigeria_MetaTags.personalBanking.homepage.title}
      description={Nigeria_MetaTags.personalBanking.homepage.description}
      bankingType={'personal'}
    >
      <Helmet>
        <link rel="canonical" href="https://moniepoint.com/ng/personal" />
        <link rel="alternate" href="https://moniepoint.com/ng/personal" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/personal" hreflang="en-ng" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "",
  "name": "Moniepoint Nigeria",
  "description": "We are a financial services company based in Nigeria, offering a range of business banking services including business accounts, business loans, pos terminal machines, expense cards, and more.",
  "image": "https://moniepoint.com/static/MFB-667f089014bfae5d706fc620b07be2e0.png",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "12, Wole Ariyo Street.",
    "addressLocality": "Lekki Phase 1",
    "addressRegion": "Lagos",
    "postalCode": "106104",
    "addressCountry": "NG"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 6.44300,
    "longitude": 3.45908
  },
  "url": "https://moniepoint.com/ng/",
  "telephone": "+01 888 9990",
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "08:00",
    "closes": "17:00"
  },
  "sameAs": [
    "https://www.facebook.com/Moniepointng/",
    "https://instagram.com/moniepointng",
    "https://twitter.com/moniepointng",
    "https://www.linkedin.com/showcase/moniepoint/"
  ]
}`}


        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"What is personal banking?","acceptedAnswer":{"@type":"Answer","text":"Personal banking refers to financial services and products offered to individuals by a bank, including savings accounts, loans, and other services tailored for personal financial management."}},{"@type":"Question","name":"What documents do I need to open a Moniepoint Personal Bank account?","acceptedAnswer":{"@type":"Answer","text":"To open an account, you only need a valid Nigerian phone number, a functional email address and your BVN. These will give you access to a functional bank account on KYC Level 1. Click here to learn more about the KYC levels."}},{"@type":"Question","name":"Is there a fee for opening an account?","acceptedAnswer":{"@type":"Answer","text":"No, there is no fee for opening or maintaining an account with Moniepoint."}},{"@type":"Question","name":"Is Moniepoint safe?","acceptedAnswer":{"@type":"Answer","text":"Yes! At Moniepoint, we take security seriously by implementing robust security measures to protect your financial information and transactions. We are also regulated by CBN and insured by NDIC"}},{"@type":"Question","name":"Can a personal bank account be used for business?","acceptedAnswer":{"@type":"Answer","text":"We recommend that you have a separate personal bank account and business account to keep track of your finances properly"}},{"@type":"Question","name":"How many personal bank accounts can I have?","acceptedAnswer":{"@type":"Answer","text":"You can have more than one personal bank account depending on your financial goal."}},{"@type":"Question","name":"How do I access my account?","acceptedAnswer":{"@type":"Answer","text":"You can access your Moniepoint Personal Bank account through the mobile app. Click here to download the app"}}]}`}</script>

      </Helmet>
      <div>

        <HeroContainer src={HeroImage} mobileSrc={HeroImageMobile}>

          {/* <div className="hero-mask"></div> */}
          <div className="hero-content-wrapper">
            <div className="hero-content">

              <h1 className="hero-title">
                <Trans>
                  Get the card <br /> that works
                </Trans>
              </h1>
              {/* used for SEO tags*/}
              <h2 className={'d-none'}>
                Bank with Moniepoint
                Pay with ease at over 1 million merchants across Nigeria.
                Enjoy fast payments with your card at these businesses and more
              </h2>
              <p className="hero-subtitle">
                <Trans>personalPage.hero_subtitle</Trans>
              </p>

              <div className={'d-flex flex-row gap-2 gap-md-4'}>
                <div>
                  <Link
                    to={'https://app.adjust.com/13owp4gc'}
                    target={'_blank'}>
                    <img src={PlayStoreImage} alt={''} className={'d-none d-sm-block'} />
                    <img src={PlayStoreImageMobile} alt={''} className={'d-block d-sm-none'} />
                  </Link>
                </div>
                <div>
                  <Link to={'https://app.adjust.com/13owp4gc'}
                    target={'_blank'}>
                    <img src={AppStoreImage} alt={''} className={'d-none d-sm-block'} />
                    <img src={AppStoreImageMobile} alt={''} className={'d-block d-sm-none'} />
                  </Link>
                </div>
              </div>

            </div>
          </div>
          <MobileHeroImage>
            <div className={'content-wrapper'}>
              <div className={'content d-flex items-align-center justify-content-center'}>
                <img src={heroCardMobile} alt="hero Card Mobile" />
              </div>
            </div>
          </MobileHeroImage>
          <div className='hero-container-image'>
            <img src={heroCardMobile} alt="hero Card Mobile" />
          </div>
        </HeroContainer>

      </div>


      <MerchantContainerDiv>
        <div className={'content d-flex flex-column'}>
          <div className={'d-flex flex-column '}>
            <div className={'pay-with-transfer'}>
              <span className={'without'}>Bank </span>
              with Moniepoint
            </div>
            <div className={'desc  d-md-none'}>
              Need to pay some bills, pay a business or send money to a friend?
              Moniepoint transfers go through every time. We mean it.
            </div>
            <div className={'desc d-none d-md-block'}>
              Need to pay some bills, pay a business or send money to a friend?<br />
              Moniepoint transfers go through every time. We mean it.
            </div>
          </div>
          <div className={'d-flex flex-md-row flex-sm-column layout-wrapper'}>
            <LayoutCard icon={CardOnHand}
              desc={'If transfers aren’t your jam, we get it. Request a debit card and have it delivered to you within 48 hours.' +
                ' Activate it in minutes, and start using it right away.'}
              title={'Instant Debit cards that always work'}
            />
            <LayoutCard icon={Mobile}
              title={'One app for all your banking needs'}
              desc={'Need to manage your money with ease? We know that your money is important to you, and we take that seriously. ' +
                'Manage your money, make payments and pay bills all from our banking app.'}
            />
            <LayoutCard icon={Checklist}
              desc={'Had a failed transaction? Say goodbye to unreversed debits. Log disputes within your app, ' +
                'and monitor them till you get a full refund.'}
              title={'Dispute resolution like never before'}
            />
          </div>
        </div>
      </MerchantContainerDiv>


      <EaseContainer>
        <div className={'content'}>
          <div className='h1 ease-heading'>
            <Trans>personalPage.ease_1</Trans>
          </div>
          <div className='ease-content'>
            <p className={'d-flex flex-row gap-4 align-items-start'}>
              <span className={'pt-2'}>
                <img src={SeamlessIcon} />
              </span>
              <Trans>personalPage.ease_2</Trans>
            </p>
            <p className={'d-flex flex-row gap-4 align-items-start'}>
              <span className={'pt-2'}>
                <img src={LocationIcon} />
              </span>
              <div>
                <Trans>personalPage.ease_3</Trans>
                <div className={'text-white-note'} >
                  <i>
                    Most deliveries will be done in 48h, but this may vary due to reasons
                    outside our
                    control.
                  </i>
                </div>
              </div>

            </p>
            <p className={'d-flex flex-row gap-4 align-items-start'}>
              <span className={'pt-2'}>
                <img src={WalletIcon} />
              </span>
              <Trans>personalPage.ease_4</Trans>
            </p>
          </div>
          <div className='ease-btn'>
            <a
              href="https://app.adjust.com/13owp4gc"
              target="_blank"
              className="btn ease-btn-clr"
            >
              <Trans>personalPage.ease_5</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </EaseContainer>

      <BankWithMoniePoint>
        <MerchantContainerDiv>
          <div className={'content d-flex flex-column'}>
            <div className={'d-flex flex-column '}>
              <div className={'pay-with-transfer'}>
                <span className={'without'}>Secure Banking</span>,
                with round the <br className={'d-none d-sm-block'} />
                clock support
              </div>
            </div>
            <div className={'d-flex flex-md-row flex-sm-column layout-wrapper'}>
              <LayoutCard icon={secure}
                title={'personalPage.tool2_4'}
                desc={'personalPage.tool2_5'}
              />
              <LayoutCard icon={protect}
                title={'personalPage.tool2_6'}
                desc={'personalPage.tool2_7'}
              />
              <LayoutCard icon={support}
                title={'Anytime, anywhere, support'}
                desc={'We’re always here to talk. Reach our support team however you need to - ' +
                  ' by phone, email, in the app, or check out the Help Center.'}
              />
            </div>
          </div>
        </MerchantContainerDiv>
        <img className='secure-bank-img' src={secureBankVector} alt="secure banking" />
      </BankWithMoniePoint>

      <BusinessContainer>
        <div className='fast-payment-container'>
          <div className='h1 fast-payment-heading'><Trans>
            Enjoy fast payments with your card at these businesses and more
          </Trans></div>
          <div className='fast-payment-imgs'>
            {businesses.map((item, index) => (
              <img src={item} key={index} />
            ))}
          </div>

          <div className='ease-btn fast-payment-btn'>
            <a
              href="https://app.adjust.com/13owp4gc"
              target="_blank"
              className="btn  fast-payment-btn-clr"
            >
              <Trans>personalPage.business_btn_acc</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>

        </div>

      </BusinessContainer>

      <FAQsContainer>
        <div className="content">
          <h3 className="title">
            <Trans>FAQs</Trans>
          </h3>
          <div className="faqs-list">
            <FaqItem
              title="What is personal banking?">
              <p className="">Personal banking refers to financial services and products offered to individuals by a bank,
                including savings accounts, loans, and other services tailored for personal financial management.</p>

            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="What documents do I need to open a Moniepoint Personal Bank account?">
              <p>
                To open an account, you only need a valid Nigerian phone number, a functional email address and your BVN. These will give you access to a functional tier one bank account after which you will be able to upgrade.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Is there a fee for opening an account?">
              <p>No, there is no fee for opening or maintaining an account with Moniepoint.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Is Moniepoint safe?">
              <p className="">Yes! At Moniepoint, we take security seriously by implementing robust security measures to protect your
                financial information and transactions. We are also regulated by CBN and insured by NDIC. </p>

            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="Can a personal bank account be used for business?">
              <p>We recommend that you have a separate personal bank account and business account to keep track of your finances properly.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="How many personal bank accounts can I have?">
              <p>You can have more than one personal bank account depending on your financial goal.</p>
            </FaqItem>
            <div className="faq-divider"></div>
            <FaqItem
              title="How do I access my account?">
              <p>You can access your Moniepoint Personal Bank account through the mobile app. Click here to download the app.</p>
            </FaqItem>
          </div>
        </div>
      </FAQsContainer>
      {/* <BBNGameBanner /> */}
    </Layout>
  );
};

export default PersonalPage;

export const FaqItem = ({ title, children, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const toggleIsOpen = () => setIsOpen((value) => !value);
  return (
    <FAQItemConatainer>
      <div className="text-section">
        <div className="faq-title" onClick={toggleIsOpen}>
          <Trans>{title}</Trans>
        </div>
        <div className={`description ${isOpen && "open"}`}>
          {children}
        </div>
      </div>
      <div className="toggle-button" onClick={toggleIsOpen}>
        {isOpen ? <CollapseMenuIcon /> : <ExpandMenuIcon />}
      </div>
    </FAQItemConatainer>
  );
};


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessTips:  allGraphCmsPost(
    sort: {fields: date, order: DESC}
    filter: {category: {categoryName: {eq: "Business Tips"}}}
  ) {
    nodes {
      slug
      title
      formattedDate
      coverImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  }
`;
const MobileHeroImage = styled.section`
  position: relative; /* Required for proper positioning of content */
  @media only screen and (min-width: 769px) {
    display: none;
  }

  .content-wrapper {
    margin: auto 20px;

    .content {
      img {
        width: 100%;
        transition: transform 0.2s ease-in-out;
        animation: bounce 3s infinite;
      }
    }
  }
`
const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 497px;
  position: relative;
  @media (max-width: 768px) {
    background: url(${(props) => props.mobileSrc});
    min-height: fit-content;
    background-size: 100% 87%;
    background-repeat: no-repeat;
  }

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 99;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
    @media (max-width: 768px) {
      padding: 140px 60px;
      height: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    background-position: inherit;
    background-size: 100% 87%;
    .hero-container-image {
      display: none;
    }

  }

  .hero-container-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 55;
    @media only screen and (min-width: 1024px) {
      top: 20% !important;
      img {
        height: 500px;
      }
    }
    @media only screen and (min-width: 1441px) {
      right: 10vw;
    }
    //@media only screen and (min-width: 769px) and (max-width: 1024px) {
    //  /* Adjust image styles for tablets */
    //  img {
    //    height: 300px;
    //    width: auto;
    //  }
    //}


    transition: transform 0.2s ease-in-out;
    animation: bounce 3s infinite;
  }

  /* Define the bounce animation */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .hero-content {
    color: white;

    .award-badge {
      display: flex;
      padding: 4px 27px 4px 16px;
      border-radius: 50px;
      background: white;
      color: #0361f0;
      gap: 4px;
      align-items: center;
      width: fit-content;
      margin-bottom: 8px;

      .award-badge-image {
        width: 32px;
        height: 32px;
      }

      span {
        font-family: "Founders Grotesk";
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
      color: black;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      font-family: inter;
      margin-bottom: 24px;
      color: black;
    }

    @media only screen and (max-width: 768px) {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }

      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }

    @media only screen and (max-width: 480px) {
      .hero-title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 12px;
      }

      .hero-subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .btn {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 38%;
    }

    // .hero-mask {
    //   background: linear-gradient(
    //     180deg,
    //     rgba(29, 29, 29, 0) 0%,
    //     rgba(29, 29, 29, 0.5) 79.14%
    //   );
    // }
    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .award-badge {
        span {
          line-height: 1;
        }
      }


      .award-container {
        max-width: 167px;
        right: 20px;

        img {
          height: 167px;
        }
      }

      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }

      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }
  }
`;
const BankWithMoniePoint = styled.section`
  position: relative;

  .secure-bank-img {
    position: absolute;
    right: 114px;
    top: 47px;
    height: 450px;
  }
`
const ToolsContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 82px 150px 77px;
    @media only screen and (max-width: 1024px) {
      padding: 82px 60px 77px;
    }

    .bank {
      color: #0361F0;
      font-weight: 600;

    }

    .need-to-pay {
      font-family: inter;
      color: black;
      font-weight: 500;
      width: 55ch;
      line-height: 32px;
      font-size: 20px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: start;
      margin-bottom: 32px;
    }

  }

  .tools-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;

    .tools-item {
      max-width: 247.28px;
      text-decoration: none;
      margin: auto;

      .tool-name {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 16px;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  background: white;
  .content {
    padding: 115.73px 20px 32px;

    // .title {
    //   font-size: 24px;
    //   line-height: 28.32px;
    //   margin-bottom: 32px;
    // }
    .tools-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .tools-item {
        max-width: 177px;
        padding: 24px 16px;
        border: 1px solid rgba(3, 87, 238, 0.05);
        border-radius: 8px;
        gap: 16px;
        filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

        .tool-name {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  background: white;
  .content {
    padding: 115.73px 20px 32px;

    // .title {
    //   font-size: 24px;
    //   line-height: 28.32px;
    //   margin-bottom: 32px;
    //   width: 100%; /* Ensure title takes full width on small screens */
    //   text-align: center; /* Center align title on small screens */
    // }
    .tools-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* Display tools in a single column */
      gap: 24px;

      .tools-item {
        max-width: 100%; /* Take full width on small screens */
        padding: 24px 16px;
        border: 1px solid rgba(3, 87, 238, 0.05);
        border-radius: 8px;
        gap: 16px;
        filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

        .tool-name {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
          text-align: center; /* Center align tool names on small screens */
        }
      }
    }
  }
}
`
const ToolsContainerTwo = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  padding-bottom: 74px;


  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 82px 150px 77px;
    @media only screen and (max-width: 1024px) {
      padding: 82px 60px 77px;
    }

    .bank {
      color: #0361F0
    }

    .need-to-pay {
      color: black;
      font-weight: 500;
      width: 55ch;
      line-height: 32px;
      font-size: 20px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: start;
      max-width: 734px;
      margin-bottom: 32px;
    }

    .tools-container-two {
      font-family: "Founders Grotesk";
      font-size: 72px;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: start;
      width: 18ch;
      margin-bottom: 32px;
    }
  }

  .tools-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;

    .tools-item {
      max-width: 247.28px;
      text-decoration: none;
      margin: auto;

      .tool-name {
        font-family: "Founders Grotesk";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 16px;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  background: white;
  .content {
    padding: 115.73px 20px 32px;

    // .title {
    //   font-size: 24px;
    //   line-height: 28.32px;
    //   margin-bottom: 32px;
    // }
    .tools-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .tools-item {
        max-width: 177px;
        padding: 24px 16px;
        border: 1px solid rgba(3, 87, 238, 0.05);
        border-radius: 8px;
        gap: 16px;
        filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

        .tool-name {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
`;
const InstantDebitCardsContainer = styled.div`
  .hover-animate:hover {
    opacity: 0.8;
    transform: translateY(-4px);

  }

  .wrapper-card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding-bottom: 100px;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .wrapper-card-support {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding-bottom: 32px;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 100px;
    }
  }

}

.debit-card {
  padding: 40px;
  height: 421px;
  width: 554px;
  border-radius: 16px;
  background: white;
  border: 1px solid #E5E7E9;
  box-shadow: -8px 20.25px 60px 0px rgba(3, 97, 240, 0.16);
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }


}

.security-card {
  box-shadow: 0px 1px 2px 0px rgba(3, 97, 240, 0.1);
  border: 1px solid rgba(229, 231, 233, 1);
  padding: 40px;
  height: 412px;
  width: 554px;
  border-radius: 16px;
  background: white;
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }
}

.debit-img {
  margin-bottom: 60px;

}

.debit-heading {
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  font-family: Founders Grotesk;
}

.debit-content {
  font-family: inter;
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 500;
  color: black;
}

.banking-img {
  margin-bottom: 60px;
}

.banking-name {
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  font-family: Founders Grotesk;
}

.banking-content {
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 500;
  color: black;
  font-family: inter;
}

.help-center {
  font-weight: 600;
  color: rgba(3, 97, 240, 1);
}

.banking-need {
  width: 554px;
  height: 472px;
  border-radius: 16px;
  background: white;
  padding: 40px;
  margin-top: 100px;
  border: 1px solid #E5E7E9;
  box-shadow: -8px 20.25px 60px 0px rgba(3, 97, 240, 0.16);
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }
}

.protection-card {

  box-shadow: 0px 1px 2px 0px rgba(3, 97, 240, 0.1);
  border: 1px solid rgba(229, 231, 233, 1);
  width: 554px;
  height: 428px;
  border-radius: 16px;
  background: white;
  padding: 40px;
  @media (max-width: 1200px) {
    width: 400px;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 320px;
    height: auto;
  }
}

.support-content {
  @media (max-width: 768px) {
    text-align: center;
  }
}

.support-card {
  margin: auto;
  display: flex;
  width: 1140px;
  align-items: center;
  justify-content: center;
  gap: 60px;
  padding: 40px;
  background: white;
  border-radius: 16px;
  border: 1px solid rgba(229, 231, 233, 1);
  box-shadow: 0px 1px 2px 0px rgba(3, 97, 240, 0.1);
  @media (max-width: 1200px) {
    width: 830px;
  }
  @media (max-width: 992px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 425px) {
    width: 320px;
  }


}

}
//  background-color: rgba(3, 87, 238, 0.03);

.content {
  max-width: 1440px;
  margin: auto;
  padding: 48px 150px 77px;
  @media only screen and (max-width: 1024px) {
    padding: 48px 60px 77px;
  }

  .bank {
    color: #0361F0
  }

  .need-to-pay {
    color: black;
    font-weight: 500;
    width: 55ch;
    line-height: 32px;
    font-size: 20px;
  }

  .title {
    font-family: "Founders Grotesk";
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: start;
    width: 10ch;
    margin-bottom: 32px;
  }

}

.tools-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;

  .tools-item {
    max-width: 247.28px;
    text-decoration: none;
    margin: auto;

    .tool-name {
      font-family: "Founders Grotesk";
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 16px;
      color: rgba(0, 0, 0, 1);
    }
  }
}

}

@media only screen and (max-width: 768px) {
  .wrapper-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .content {
      padding: 115.73px 20px 32px;

      .title {
        font-size: 24px;
        line-height: 28.32px;
        margin-bottom: 32px;
      }

      .tools-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .tools-item {
          max-width: 177px;
          padding: 24px 16px;
          border: 1px solid rgba(3, 87, 238, 0.05);
          border-radius: 8px;
          gap: 16px;
          filter: drop-shadow(0px 1px 2px rgba(3, 97, 240, 0.1));

          .tool-name {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
const BusinessContainer = styled.div`
  background: rgba(242, 252, 255, 0.5);
  @media only screen and (max-width: 768px) {
    .fast-payment-heading {
      margin: auto 20px auto 20px;
      text-align: center !important;
      font-family: 'Founders Grotesk';
      font-size: 24px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 100% !important;
    }

    .ease-heading {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%
    }
  }

  .fast-payment-container {
    margin: 0 auto;
    max-width: 968px;

  }

  .fast-payment-heading {
    padding: 48px 0;
    font-family: Founders Grotesk;
    font-size: 48px;
    font-weight: 500;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;

  }

  .fast-payment-imgs {
    display: flex;
    gap: 21px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .fast-payment-btn {
    display: flex;
    justify-content: center;
    padding: 48px 0;
  }

  .fast-payment-btn-clr {

    padding: 12px 24px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    border-radius: 8px;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    :hover {
      opacity: 0.8;
      transform: translateY(-4px);
      color: white;
      background-color: rgba(0, 0, 0, 1);
      border-color: rgba(0, 0, 0, 1);
    }

    svg {
      path {
        fill: white;
      }
    }

  }



`;
const EaseContainer = styled.div`
  background: rgba(2, 56, 139, 1);
  padding: 118px 0;

  .content {
    margin: auto 10.3%;
  }

  @media only screen and (min-width: 1441px) {
    .content {
      margin: auto 16%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .content {
      margin: auto 7%;
    }
  }


  .ease-heading {
    margin: 0 auto;
    text-align: center;

    font-family: Founders Grotesk;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 723px;

    margin-bottom: 60px;
    color: white;
    // padding-top:118px;
  }

  .ease-content {
    font-family: inter;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 27px;
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 60px;
    color: white;
  }
  .text-white-note{ 
    font-size :10px;
    padding-top:5px;
    line-height :10px;
  }

  .ease-btn {
    justify-content: center;
    align-items: center;
    display: flex

  }

  .ease-btn-clr {

    padding: 12px 24px;
    background-color: rgba(254, 215, 91, 1);
    color: black;
    border-radius: 8px;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    :hover {
      opacity: 0.8;
      transform: translateY(-4px);
      color: black;
      background-color: rgba(254, 215, 91, 1);
      border-color: rgba(254, 215, 91, 1);
    }

    svg {
      path {
        fill: black;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background: rgba(2, 56, 139, 1);
    padding: 50px 0;
    .content {
      margin: auto 20px auto 20px;
    }

    .ease-content {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      gap: 14px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 40px;
      color: white;
    }

    .ease-heading {
      text-align: start;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 60px;
      color: white;
      // padding-top:118px;
    }
`;


const FAQItemConatainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .toggle-button {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .text-section {
    .faq-title {
      max-width: 895px;
      font-family: "Founders Grotesk";
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 0px;
      cursor: pointer;
    }

    .description {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0px;
      display: none;

      &.open {
        display: block;
        margin-top: 16px;
      }

      ul.faq-list {
        padding-left: 32px;
        margin-top: 12px;

        li.faq-list-item {
        margin-bottom: 12px;
      }}
    }
  }

  @media only screen and (max-width: 768px) {
    gap: 26px;

    .text-section {
    }
  }
`;

const FAQsContainer = styled.section`
  background-color: white;

  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }
    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 554px;
      margin: 0 auto 48px;
      color: rgba(0, 0, 0, 1);
    }

    .faqs-list {
      width: 100%;

      .faq-divider {
        width: 100%;
        height: 1px;
        background: rgba(3, 87, 238, 0.1);
        margin: 32px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;

      .title {
        font-size: 48px;
        line-height: 37.76px;
        margin: 0 auto 48px;
        max-width: 288px;
      }

      .faqs-list {
      }
    }
  }
`;


