import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import bgImage from "../../images/nigeria/personal/game/bbn-bg.png";
import {StaticImage} from 'gatsby-plugin-image';
import {Trans} from 'react-i18next';
import {ArrowRightShortIcon} from '../../images/nigeria/Icons';
import {Link} from 'gatsby';

const BBNGameBanner = () => {

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowBanner(true)
        }, 3000);
    }, [])

    const closeBanner = () => setShowBanner(false)


    if (!showBanner) {
        return <></>;
    }

    return (
        <BannerContainer>
            <div className="bg-overlay" onClick={closeBanner}></div>

            <div className="banner-content">
                <div className="overlay"></div>
                <StaticImage
                    loading={'eager'}
                    alt="..."
                    src="../../images/nigeria/personal/game/bbn-game-banner.png"
                    className="banner-image"
                />
                <StaticImage
                    alt="..."
                    src="../../images/nigeria/personal/game/bbn-game-badges.png"
                    className="badge-image"
                />
                <div className="qualify-text">
                    QUALIFY, PLAY & WIN!!
                </div>
                <p className="text-content">
                    Earn Money when you use your Moniepoint account this BB Naija season! Get access to win millions of
                    Naira weekly with every transaction.
                </p>

                <div className="action-section">
                    <Link href="/ng/personal/bbn-game" className="btn btn-primary faded w-100">
                        <Trans>Learn More</Trans>
                        <ArrowRightShortIcon size={24} color='white'/>
                    </Link>

                    <a href="https://app.adjust.com/13owp4gc" target="_blank" className="btn btn-primary w-100">
                        <Trans>Play Now</Trans>
                        <ArrowRightShortIcon size={24}/>
                    </a>
                </div>

            </div>
        </BannerContainer>
    )
}

export default BBNGameBanner


const BannerContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: position 1.5s ease-in-out;


  .bg-overlay {
    width: 100%;
    height: 100%;
    position: absolute
  }


  .banner-content {
    max-width: 601px;
    width: 100%;
    border-radius: 30px;
    background: radial-gradient(102.07% 78.58% at 50.92% 29.82%, #0142BA 0%, #011A49 57.73%);

    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    align-items: center;
    padding: 0px 44px 48px;
    position: relative;
    overflow: clip;

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      background: url(${bgImage});
      opacity: 0.6;
      background-blend-mode: overlay;
    }

    .banner-image {
      width: 100%;
      object-fit: cover;
      margin-bottom: 0;
      overflow: visible;

      img {
        overflow: visible;
      }
    }

    .badge-image {
      max-width: 220px;
      width: 100%;
      margin-bottom: 23px;
      margin-top: -98px;
      position: relative;
      mix-blend-mode: hard-light;
      opacity: 0.9;
    }


    .qualify-text {
      border: 1px solid #F2F7FE;
      border-radius: 20px;
      font-size: 12px;
      line-height: 17.76px;
      font-weight: 600;
      color: #F2F7FE;
      padding: 4px 12px;
      text-align: center;
      position: relative;
    }

    .text-content {
      max-width: 393px;
      width: 100%;
      margin: 19px auto;
      position: relative;
    }

    .action-section {
      display: flex;
      gap: 23px;
      justify-content: space-between;
      align-items: center;
      max-width: 393px;
      width: 100%;
      position: relative;

      .btn.btn-primary.faded {
        background-color: #FFFFFF4D;
        border: none;
      }
    }


  }

  @media screen and (max-width: 768px) {


    .banner-content {
      padding: 0px 20px 40px;
      .badge-image {
        margin-top:-56px;
      }
      .action-section {
        // flex-direction: column-reverse;
        gap: 12px;

        svg {
          display: none;
        }
      }
    }
  }



`;